import type { $Fetch } from 'ofetch'

export interface MedicalCode {
  text: string
}

export class MedicalCodesApi {
  constructor(private readonly apiFetch: $Fetch) {}

  async list(): Promise<MedicalCode[]> {
    return await this.apiFetch('/medical_codes')
  }
}
