import type { $Fetch } from 'ofetch'

interface HighlightRect {
  x1: number
  y1: number

  x2: number
  y2: number

  width: number
  height: number

  pageNumber?: number
}

interface HighlightPosition {
  boundingRect: HighlightRect
  rects: Array<HighlightRect>
  pageNumber: number
  concatenatedPdfPageNumber?: number
}

export interface Annotation {
  id: string
  medicalFileId: string
  highlightText: string | null
  highlightImage: string | null
  highlightImageWithContext: string | null
  highlightPosition: HighlightPosition | null
  medicalCodeText: string | null
  rationaleText: string | null
  tags: string[] | null
}

export interface NewAnnotation extends Omit<Annotation, 'id'> {}

export interface SummaryPatient {
  id: string
  firstName: string
  lastName: string
  birthDate: Date
}

export interface AnnotationsGroup {
  groupId: string

  // title/group key
  medicalCodeText: string

  // all annotations for medicalCodeText
  annotations: Annotation[]

  // all hcc_codes for medicalCodeText
  hccCodes: number[]
}

export type AnnotationsGroupList = AnnotationsGroup[]

export interface Medication {
  code: string | null
  codeDescription: string | null
  userSelected: boolean | null
  codeText: string | null
  dosageInstruction: string | null
  dosageTiming: string | null
  dosageStatus: string | null
  intent: string | null
  dispenseStart: string | null
  dispenseEnd: string | null
  authoredOn: string | null
}
export type Medications = Medication[]

export interface Lab {
  component: string | null
  value: string | null
  referenceRange: string | null
  notes: string | null
}
export type Labs = Lab[]

export interface Vital {
  component: string | null
  value: string | null
  unit: string | null
  issued: string | null
}
export type Vitals = Vital[]

export interface AnnotationSummary {
  patient: SummaryPatient
  items: AnnotationsGroupList
  navLinks: boolean
}

export class AnnotationApi {
  constructor(private readonly apiFetch: $Fetch) {}

  async createAnnotation(annotation: NewAnnotation): Promise<Annotation> {
    return await this.apiFetch('/annotations', { method: 'POST', body: annotation })
  }

  async findAllAnnotationsByPatientId(patientId: string): Promise<Annotation[]> {
    return await this.apiFetch(`/annotations/${patientId}`)
  }

  async getAnnotationSummary(patientId: string, navLinks?: boolean): Promise<AnnotationSummary> {
    const query = navLinks ? { navLinks: true } : undefined
    const response = await this.apiFetch(`/annotations/${patientId}/summary`, { query })
    response.patient.birthDate = new Date(response.patient.birthDate)
    return response
  }

  async updateAnnotation(annotation: Annotation): Promise<Annotation> {
    return await this.apiFetch('/annotations', { method: 'PATCH', body: annotation })
  }

  async deleteAnnotation(annotationId: string): Promise<Annotation> {
    return await this.apiFetch('/annotations', { method: 'DELETE', body: { id: annotationId } })
  }
}
